<template>
  <router-view />
</template>
<script>
import { version } from '../constant'

export default {
  data() {
    return {
      version: version,
    }
  },
}
</script>
